import { GetForcedPasswordLoginDisplayTokenIsValidBySubdomainResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const getForcedPasswordLoginDisplayTokenIsValidBySubdomain =
  async (params: {
    subdomain: string
    forced_password_login_display_token: string
  }) => {
    const url = `${fullUrl(
      'api',
    )}/teams/forced_password_login_display_token_is_valid_by_subdomain`
    return axiosClient.get<GetForcedPasswordLoginDisplayTokenIsValidBySubdomainResponse>(
      url,
      { params },
    )
  }
