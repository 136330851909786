import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'
import { PostLoginRequest } from 'types/ApiRequest'
import { PostLoginResponse } from 'types/ApiResponse'

export const postLogin = async (params: PostLoginRequest) => {
  const { data } = await axiosClient.post<PostLoginResponse>(
    `${fullUrl()}/login`,
    params,
  )
  return data
}
