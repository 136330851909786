import React, { useState, useEffect } from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { getFingerprintAndHash } from 'scripts/getFingerprintAndHash'
import { Main } from 'presentation/pages/login/main'
import { getTeam } from 'api/getTeam'
import { useIpAddress } from 'api/useIpAddress'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { navigate } from 'gatsby'
import { FingerPrint, FingerPrintHash } from 'types/FingerPrint'
import { getExternalIdps } from 'api/getExternalIdps'
import { ExternalIdp } from 'types/ExternalIdp'
import { Loading } from 'presentation/components/Loading'
import { StringParam, useQueryParam } from 'use-query-params'
import { getForcedPasswordLoginDisplayTokenIsValidBySubdomain } from 'api/getForcedPasswordLoginDisplayTokenIsValidBySubdomain'

export const Login = () => {
  const [fingerprint, setFingerprint] = useState<FingerPrint>('')
  const [fingerprintHash, setFingerprintHash] = useState<FingerPrintHash>('')
  const [userAgent, setUserAgent] = useState('')
  const [usePasswordAuthenticationInWeb, setUsePasswordAuthenticationInWeb] =
    useState<boolean | undefined>(undefined)
  const [externals, setExternals] = useState<ExternalIdp[]>([])
  const [teamId, setTeamId] = useState<number>()
  const [{ ipAddress }] = useIpAddress()
  const [appClientKeyQueryParam] = useQueryParam('app_client_key', StringParam)
  const [forcedPasswordLoginDisplayTokenQueryParam] = useQueryParam(
    'forced_password_login_display_token',
    StringParam,
  )
  const [
    hasValidForcedPasswordLoginDisplayToken,
    setHasValidForcedPasswordLoginDisplayToken,
  ] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const subdomain = getSubdomain(window.location.host)
      if (subdomain === '') {
        navigate('/', { replace: true })
        return
      }

      const { data, status } = await getTeam(subdomain)
      if (status !== 200) {
        navigate('/404', { replace: true })
        return
      }

      setTeamId(data.team.id)
      getFingerprintAndHash().then(([fingerprint, fingerprintHash]) => {
        setFingerprint(fingerprint)
        setFingerprintHash(fingerprintHash)
      })
      setUserAgent(navigator.userAgent)
      setUsePasswordAuthenticationInWeb(
        data.team.team_authentication_setting
          .use_password_authentication_in_web,
      )
      getExternalIdps(subdomain).then(({ external_idps }) => {
        setExternals(external_idps)
      })
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (
        typeof forcedPasswordLoginDisplayTokenQueryParam !== 'string' ||
        forcedPasswordLoginDisplayTokenQueryParam === ''
      ) {
        return
      }

      const subdomain = getSubdomain(window.location.host)
      if (subdomain === '') {
        return
      }

      const { data, status } =
        await getForcedPasswordLoginDisplayTokenIsValidBySubdomain({
          forced_password_login_display_token:
            forcedPasswordLoginDisplayTokenQueryParam,
          subdomain,
        })
      if (status !== 200) {
        return
      }

      setHasValidForcedPasswordLoginDisplayToken(
        data.is_valid_forced_password_login_display_token,
      )
    })()
  }, [forcedPasswordLoginDisplayTokenQueryParam])

  if (teamId) {
    return (
      <Layout dataTest="loginPage">
        <Main
          teamId={teamId}
          externals={externals}
          ipAddress={ipAddress ?? ''}
          userAgent={userAgent}
          fingerprint={fingerprint}
          fingerprintHash={fingerprintHash}
          appClientKey={appClientKeyQueryParam}
          usePasswordAuthenticationInWeb={usePasswordAuthenticationInWeb}
          hasValidForcedPasswordLoginDisplayToken={
            hasValidForcedPasswordLoginDisplayToken
          }
        />
      </Layout>
    )
  }

  return (
    <main className="pt-10 pb-20 w-full flex justify-center">
      <Loading />
    </main>
  )
}
